/* Footer.css */
.footer {
    position: relative;
    /* Removed fixed positioning */
    width: 100%;
    text-align: center;
    padding-bottom: env(safe-area-inset-bottom);
    background-color: transparent;

}

.footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 1rem 0 2rem;
}

.footer-text {
    font-size: 0.8rem;
}

.footer-logo {
    width: 24px;
    height: 24px;
    object-fit: contain;
}