.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #000;
    padding: 5px 20px;
    max-width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    height: 10vh;
}

.navbar-content {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.user-info {
    display: flex;
    align-items: center;
    gap: 10px;
}

.avatar {
    background-color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.user-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 1.2rem;
}

.user-name {
    font-weight: bold;
    color: #fff;
}

.user-role {
    font-size: 12px;
    color: #666;
}

.logout-button {
    margin-left: auto;
}

.navbar-button {
    padding: 8px 15px;
    background-color: #444;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.navbar-button:hover {
    background-color: #555;
}

.navbar-actions {
    display: flex;
    align-items: center;
    gap: 15px;
}

.icon-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #fff;
    transition: color 0.3s;
}

.icon-button:hover {
    color: #007bff;
}

.user-role-badge {
    margin-top: 0.3rem;
    display: inline-block;
    padding: 0rem 0.6rem;
    font-size: 0.6rem;
    border-radius: 10px;
    color: white;
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
}

.badge-admin {
    background-color: red;
}

.badge-guru {
    background-color: #007bff;
}

.badge-murid {
    background-color: green;
}