/* General styles for the app */
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;

  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* Makes sure it fills the entire viewport height */
}

/* Main content */
main {
  flex: 1;
  /* Makes the main content take up available space */
  padding: 1rem;
  /* Optional padding */
}

.page-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 100%;
}

.appbar {
  background-color: #000;
  color: #fff;
  padding: 1rem 0;
  text-align: center;
  margin: 0;
  width: 100vw;
  box-sizing: border-box;
}

.appbar-logo {
  height: 40px;
  object-fit: contain;
}

.footer-login {
  text-align: center;
  background-color: #f1f1f1;
  font-size: 0.75rem;
  color: #666;
  padding: 0.5rem 0 2rem;
  margin: 0;
}

.footer p:last-child {
  font-size: 0.65rem;
  margin-top: 0.2rem;
}

/* Shared input styles */
.input-container {
  position: relative;
  width: 100% !important;
}

.input-field {
  width: 100%;
  padding: 1rem;
  border-radius: 8px;
  font-size: 1rem;
  box-sizing: border-box;
  border: 1px solid #ddd;
  transition: border-color 0.3s ease;
}

.input-field:focus {
  border-color: #000 !important;
  outline: none;
}

.floating-label {
  position: absolute;
  top: 1.15rem;
  left: 1rem;
  font-size: 1rem;
  color: #aaa;
  transition: all 0.3s ease;
  pointer-events: none;
}

.input-field:focus+.floating-label,
.input-field:not(:placeholder-shown)+.floating-label {
  top: -0.5rem;
  font-size: 0.8rem;
  color: #848484;
  background-color: #f9f9f9;
}

.login-button {
  width: 100%;
  padding: 1rem;
  margin: 0;
  border-radius: 8px;
  font-size: 1rem;
  background-color: #000;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #333;
}

.error-message {
  color: red;
  margin-top: 1rem;
}


/*login*****************************/
.main-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container {
  padding: 1rem 3rem;
  border-radius: 8px;
  box-shadow: none;
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  box-sizing: border-box;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  box-sizing: border-box;
  margin: 2rem 0;
}

/*register*************************/
.role-selection-container {
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 1rem;
}

.option-container {
  flex: 1;
  max-width: 400px;
  padding: 0.8rem 1rem 0;
  border: 2px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease, transform 0.2s ease;
}

.option-container h2 {
  margin: 0;
  font-size: 1.2rem;
  color: #333;
}

.option-container p {
  font-size: 0.8rem;
  color: #666;
  margin-top: 0.5rem;
}

.option-container.active {
  background-color: #fff;
  border-color: #000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
}

.option-container:hover {
  background-color: #eee;
  border-color: #000;
  transform: scale(1.02);
}