.student-details-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 1200px;
    width: 100vw;
    min-height: 90vh;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    /* Ensure padding doesn't overflow */
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Center content horizontally */
    overflow: hidden;
    /* Space for the footer */

    /* Prevent overflow */
}

.flex-container {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    /* Add some spacing between the items */
    margin-bottom: 10px;
    padding: 0 2rem;
    /* Add margin to separate from other elements */
}

.child-flex {
    flex: 1;
    /* Make all child items take up equal width */
    text-align: center;
    /* Center align the text */
}

.child-flex input {
    width: 100%;
    /* Ensure the input takes full width */
    box-sizing: border-box;
    /* Include padding and border in width */
}

.student-detail-footnote {
    font-size: 14px;
}

.student-avatar-container {
    text-align: center;
    margin-bottom: 20px;
    width: 100%;
}

.name-student {
    font-size: 20px;
    font-weight: bold;
}

.detail-student {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.juz-badge {
    display: inline-block;
    padding: 0.25em 0.5em;
    font-size: 0.9rem;
    font-weight: bold;
    color: #fff;
    background-color: #000;
    /* Blue background */
    border-radius: 10px;
    /* Rounded corners */
    text-align: center;
    white-space: nowrap;
    /* Prevent text wrapping */
    vertical-align: middle;
    margin-top: 5px;
}



.student-avatar-detail {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #fff;
}

.student-name-detail {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.detail-student-school {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 80px;
}



.save-progress-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.save-progress-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
}

.save-progress-button:hover {
    background-color: #0056b3;
}

.status-message {
    color: green;
    text-align: center;
    margin-top: 10px;
}

/* Button Group Container */
.button-group {
    display: flex;
    justify-content: center;
    /* Center the buttons horizontally */
    gap: 1rem;
    /* Add spacing between the buttons */
    margin-top: 1.5rem;
    /* Add some spacing above the button group */
}

/* Save Button */
.save-button {
    background-color: #007bff;
    /* Primary blue color */
    color: #fff;
    /* White text */
    border: none;
    font-weight: bold;
    /* Bold for emphasis */
    padding: 0.75rem 1.5rem;
    /* Button padding */
    border-radius: 8px;
    /* Rounded corners */
    font-size: 1rem;
    /* Adjust font size */
    cursor: pointer;
    /* Pointer cursor */
    transition: background-color 0.3s ease;
    /* Smooth hover effect */
}

/* Save Button Hover Effect */
.save-button:hover {
    background-color: #0056b3;
    /* Darker blue on hover */
}

/* Back Button */
.back-button {
    background-color: #f9f9f9;
    /* Light grey background */
    color: #333;
    /* Darker text color */
    border: 1px solid #ddd;
    /* Border for the button */
    padding: 0.75rem 1.5rem;
    /* Button padding */
    border-radius: 8px;
    /* Rounded corners */
    font-size: 1rem;
    /* Adjust font size */
    cursor: pointer;
    /* Pointer cursor */
    transition: background-color 0.3s ease, border-color 0.3s ease;
    /* Smooth hover effect */
}

/* Back Button Hover Effect */
.back-button:hover {
    background-color: #eee;
    /* Slightly darker grey on hover */
    border-color: #ccc;
    /* Darker border on hover */
}

.title-rekod-bacaan {
    position: absolute;
    top: 17rem;
    font-weight: bold;
    font-size: 24px;
    background-color: #000;
    padding: .2rem 1rem;
    border-radius: 18px;
    color: white;

}

.progress-form {
    flex-grow: 1;
    /* Push the footer to the bottom */

    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 1rem 0;
    background-color: #fff;
    border-radius: 10px;
    width: 100%;
    max-width: 400px;
    margin: 1rem 2rem 0;
    border: 1px solid #000;
    box-sizing: border-box;
    /* Ensure padding doesn't break layout */

}

.form-group {
    margin-top: 1rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.form-label {
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

.form-control {
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    width: 100%;
}

.form-control-ayat {
    text-align: center;
    font-weight: bold;
    font-size: larger;
}

.form-control-ayat-disable {
    text-align: center;
    font-weight: bold;
    font-size: larger;
}

.form-control:disabled {
    background-color: #f5f5f5;
    color: #888;
}

.form-control-ulasan {
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    /* Allow vertical resizing */
    background-color: #fff;
    width: 80%;
    height: 3rem;
    /* Include padding in width calculation */
    /* Add space above textarea */
}

.arabic-name {
    font-family: 'Noto Naskh Arabic', serif;
    /* Use a font that supports Arabic well */
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    direction: rtl;
    /* Ensure proper rendering for Arabic */
    text-align: center;
}