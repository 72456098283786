/* General dashboard container styling */
.dashboard-container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    background-color: #f9f9f9;
    color: #333;
    text-align: center;
    max-width: 1200px;

    padding-bottom: 60px;
    /* Ensure enough space for the footer */
    box-sizing: border-box;
    /* Includes padding in width/height calculation */
    min-height: 90vh;
    /* Stretch to full viewport height */
}

/* Dashboard header */
.dashboard-container h1 {
    font-size: 2rem;
    font-weight: bold;
    color: #222;
}

/* Section for schools and classrooms */
.dashboard-section {
    text-align: left;
    padding: 0 1rem;
}

.dashboard-section-school {
    text-align: left;
    padding: 0.5rem 1rem;
    background-color: #debe82;
}

.dashboard-section h2 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #444;
}


.school-box-scroll-container {
    display: flex;
    /* Align items horizontally */
    overflow-x: auto;
    /* Enable horizontal scrolling */
    gap: 16px;
    /* Space between school boxes */
    padding: 8px 10px 8px;
    /* Optional padding */
    scrollbar-width: none;
    /* For modern browsers, optional */
}

.school-box-scroll-container::-webkit-scrollbar {
    height: 8px;
    /* Height of the scrollbar */
}

.school-box-scroll-container::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Scrollbar color */
    border-radius: 4px;
    /* Round edges */
}

.school-box-scroll-container::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    /* Darker on hover */
}


.dashboard-section-school .school-box.active {
    border: 2px solid black;
    /* Ensure this rule has higher specificity */
}

.school-box.active {
    background-color: #f5f5f5;
    /* Ensure a light background for the active box */
    border: 2px solid black !important;
    /* Add a black border */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* Optional: Add a subtle shadow */
    transform: scale(1.05);
    /* Slightly enlarge for emphasis */
}

.school-box:hover {
    transform: scale(1.03);
    /* Optional: enlarge on hover */
    background-color: #f0f0f0;
    /* Optional hover color */
}



/* School option container styling */
.option-container-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.option-container {
    flex: 1;
    max-width: 400px;
    padding: 1rem;
    text-align: center;
    border: 2px solid #ddd;
    border-radius: 8px;
    cursor: pointer;
    background-color: #fff;
    transition: all 0.3s ease;
}

.option-container.active {
    border-color: #000;
    background-color: #f0f0f0;
    transform: scale(1.05);
}

.option-container:hover {
    background-color: #f5f5f5;
    border-color: #444;
}

/* Classrooms and teachers lists */
ul {
    list-style: none;
    padding: 0;
}

ul li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 0.8rem 1rem;
    border-radius: 8px;
    margin-bottom: 0.5rem;
    transition: background-color 0.3s ease;
}

ul li:hover {
    background-color: #f7f7f7;
}

ul li button {
    padding: 0.5rem 1rem;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

ul li button:hover {
    background-color: #218838;
}

/* Form for creating classrooms */
form {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

form input {
    flex: 1;
    padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
}

form button {
    padding: 0.8rem 1rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

form button:hover {
    background-color: #0056b3;
}

/* Status messages */
.dashboard-container p {
    margin: 1rem 0;
    color: #666;
}

.dashboard-container p.error {
    color: red;
}

.dashboard-container p.success {
    color: green;
}

.school-box-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin: 2rem;
    justify-content: start;
    /* Center-align the boxes */
}

.school-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    max-width: 500px;
    height: 30px;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #ffffff;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.3s ease;
    text-align: center;
}

.school-box.active {
    border-color: #000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: scale(1.05);
}

.school-box:hover {
    transform: scale(1.02);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
}

.school-logo {
    width: 40px;
    height: 40px;
    object-fit: contain;
    margin-right: 0.5rem;
}

.school-label {
    display: flex;
    flex-direction: column;
    text-align: start;
    min-width: 6rem;
    width: 9rem;
    overflow: hidden;

}

.school-name {
    font-size: 14px;
    color: #333;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}



.school-id {
    font-size: 10px;
    color: #999;
    padding-top: 0.2rem;
}

.teacher-name {
    font-size: 12px;
    color: #333;
    text-align: start;
    width: 5rem;
}

.kelas-tasmik-header {
    display: flex;
    align-items: center;
    justify-content: start;
    margin: 0 !important;

}

.add-icon {
    background-color: #007bff;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    padding: 0.15rem 0.45rem;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 1rem;
}

.add-icon:hover {
    background-color: #0056b3;
}

.classroom-form {
    display: flex !important;
    flex-direction: row !important;
    /* Arrange items side by side */
    align-items: center;
    /* Align items vertically in the center */
    gap: 10px;
    /* Add spacing between the input and the button */
    margin-top: 1rem;
    /* Add some space above the form */
}

.classroom-input {
    flex: 1;
    /* Allow the input to take up available space */
    padding: 0.8rem;
    border-radius: 4px;
    border: 1px solid #ddd;
    font-size: 1rem;
    box-sizing: border-box;
}

.create-button {

    padding: 0.8rem 1.5rem;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    flex-shrink: 0;
    /* Prevent the button from shrinking */
}

.create-button:hover {
    background-color: #0056b3;
}


.close-button {
    background-color: transparent;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    color: #aaa;
    transition: color 0.3s ease;
}

.close-button:hover {
    color: #000;
}

form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

form input {
    padding: 0.8rem;
    border-radius: 4px;
    border: 1px solid #ddd;
    font-size: 1rem;
}

form button {
    padding: 0.8rem;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

form button:hover {
    background-color: #0056b3;
}

/*Classroom*******************************************/
.classroom-scroll-container {
    display: flex;
    gap: 16px;
    /* Space between classroom boxes */
    overflow-x: auto;
    /* Enable horizontal scrolling */
    padding: 8px 0;
    /* Add some padding for spacing */
    scrollbar-width: none;
    /* For Firefox */
    scrollbar-color: #ccc #f9f9f9;
    /* Custom scrollbar colors */
}

.classroom-scroll-container::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar for WebKit browsers */
    /* Horizontal scrollbar height */
}

.classroom-scroll-container::-webkit-scrollbar-thumb {
    background-color: #ccc;
    /* Scrollbar thumb color */
    border-radius: 4px;
}

.classroom-scroll-container::-webkit-scrollbar-track {
    background-color: #f9f9f9;
    /* Scrollbar track color */
}

.classroom-box {
    flex: 0 0 200px;
    /* Fixed width for each box */
    height: 4%;
    /* Fixed height for consistency */
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    cursor: pointer;
    text-align: center;
    transition: transform 0.3s, background-color 0.3s, box-shadow 0.3s;
}

.classroom-box:hover {
    background-color: #f0f0f0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Hover effect */
    transform: translateY(-3px);
    /* Subtle lift effect */
}

.classroom-box.active {
    border-color: #007bff;
    background-color: #e7f1ff;
    box-shadow: 0 4px 12px rgba(0, 123, 255, 0.3);
    /* Highlight active selection */
}

.classroom-name {
    font-size: 1rem;
    color: #333;
}


/*Students********************************/
.students-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* Space between student containers */
}

.student-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.student-container:hover {
    background-color: #f0f0f0;
    transform: scale(1.02);
}

.student-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

.student-details {
    display: flex;
    flex-direction: column;
}

.student-title {
    display: flex;
    justify-content: center;
    align-items: center;
}

.student-name {
    font-size: 1rem;
    font-weight: bold;
    color: #333;
    margin: 1rem;
}

.no-students-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.no-students-image {
    width: 100px;
    /* Adjust size as needed */
    height: auto;
    margin-bottom: 10px;
    /* Spacing between image and text */
}

.no-students-message {
    font-size: 1rem;
    color: #555;
    /* Adjust color to match your design */
    text-align: center;
}


.juz-badge-dashboard {
    display: inline-block;
    background-color: #000;
    color: white;
    font-size: 0.7rem;
    font-weight: bold;
    padding: 0.2rem;
    border-radius: 15px;
    margin-left: auto;
    /* Push the badge to the right */
    text-align: center;
    min-width: 35px;
}

.admin-badge {
    height: 2rem;
    width: 2rem;
    display: flex;
    /* Use flexbox for centering */
    align-items: center;
    /* Center items vertically */
    justify-content: center;
    /* Center items horizontally */
    background-color: #000;
    /* Black background */
    color: white;
    /* White text */
    font-size: 0.5rem;
    /* Adjust font size for better fit */
    border-radius: 50%;
    /* Make it a circle */
    margin-left: auto;
    /* Push the badge to the right */
    text-align: center;
    margin: 0.5rem;
    /* Text alignment */
}

.disabled {
    pointer-events: none;
    opacity: 0.6;
}

.student-details-wrapper {
    position: fixed;
    /* Fix the position to the viewport */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fadeaa;
    /* Same background to maintain UI consistency */
    z-index: 10;
    /* Ensure it appears above other components */
    overflow-y: auto;
    /* Allow scrolling if content overflows */
}

.pending-container {

    padding: 0 1rem;
}

.pending-container h3 {
    text-align: start;
    margin-bottom: 0;

}